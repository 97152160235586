// eslint-disable-next-line no-undef
jQuery(document).ready(($) => {
  /* IE popup
  ========================================================================== */

  function checkIfIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      // eslint-disable-next-line no-alert
      alert(
        'For an optimal viewing experience, please choose a different browser.'
      );
    }

    return false;
  }

  checkIfIE();

  /* ACCORDIONS
  ========================================================================== */

  const acc = document.getElementsByClassName('accordion');
  let i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener('click', function () {
      this.classList.toggle('active');
      const panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = `${panel.scrollHeight}px`;
      }
    });
  }

  $('.return-point-container').click(function (e) {
    $('.return-point-container').removeClass('selected');
    $(this).addClass('selected');
  });

  /* EQUALISE ELIGIBLE CONTAINER COLUMN HEIGHTS
  ========================================================================== */

  $(
    '#eligible-containers-comparison .columns .grid-50:nth-child(2) .column-container'
  ).css({
    height: `${$(
      '#eligible-containers-comparison .columns .grid-50:nth-child(1) .column-container'
    ).height()}px`,
  });

  $('.column .image,.column .heading').on('click', function () {
    $(this).nextAll('.text').slideToggle('fast');
  });

  $('.columns.scroll-x').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    autoplay: false,
    fade: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  /* Home success dashboard, News and Containers horizontal scroll
  ========================================================================== */

  if (
    $('.home').length &&
    (window.innerWidth > 0 ? window.innerWidth : window.screen.width) < 767
  ) {
    $(
      '.home-performance--mobile > .elementor-container,.home-latest-news .elementor-posts,.home-containers .elementor-container'
    ).slick({
      dots: false,
      arrows: false,
      speed: 600,
      slidesToShow: 1,
      autoplay: false,
      fade: false,
      centerMode: true,
      centerPadding: '30px',
    });
  }

  /* Odometer
  ========================================================================== */

  const { Odometer } = window;

  // Callback function to execute when mutations are observed
  const callback = (mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class' &&
        mutation.target.classList.contains('animated') &&
        !mutation.target.dataset.animated
      ) {
        $(mutation.target)
          .find('.home-performance__item .elementor-image-box-title > span')
          .each((i, el) => {
            const oldStats = $(el).text();

            new Odometer({
              el,
              value: 0,
              theme: 'default',
            }).update(oldStats);
          });

        mutation.target.dataset.animated = true;
      }
    });
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  $('.home-performance__column').each((index, element) => {
    observer.observe(element, { attributes: true });
  });

  /* // Later, you can stop observing
  observer.disconnect(); */
});
